<template>
  <div>
    <div v-if="$store.state.selectedSubjectMode == 1">
      <CRow @click.self="updateStdList">
        <CCol lg="12" v-if="this.$store.state.studentList">
          <strong>รายชื่อนักเรียนห้อง
            {{ this.$store.state.selectedLevel.level_abv_name }}/{{
      this.$store.state.selectedClass
    }}
          </strong>
          <div v-for="(std, n) in this.$store.state.studentList" :key="std.id" :id="'stdDiv' + n"
            class="alert alert-success">
            เลขที่ {{ std.std_ordinal }}: {{ std.stud_id }} {{ std.prefix_name }}{{ std.std_name }}
            {{ std.std_surname }}
            <div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :name="'rDo' + std.stud_id" :id="'rDo' + std.stud_id"
                  :value="std.attType" checked @change="updateStdList(n, 1)" />
                <label class="form-check-label" :for="'rDo' + std.stud_id">มา</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :name="'rDo' + std.stud_id" :id="'rDo' + std.stud_id"
                  :value="std.attType" @change="updateStdList(n, 2)" />
                <label class="form-check-label" :for="'rDo' + std.stud_id">ลา</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :name="'rDo' + std.stud_id" :id="'rDo' + std.stud_id"
                  :value="std.attType" @change="updateStdList(n, 3)" />
                <label class="form-check-label" :for="'rDo' + std.stud_id">ขาด</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" :name="'rDo' + std.stud_id" :id="'rDo' + std.stud_id"
                  :value="std.attType" @change="updateStdList(n, 4)" />
                <label class="form-check-label" :for="'rDo' + std.stud_id">หนีเรียน</label>
              </div>
            </div>
          </div>
        </CCol>
        <CCol lg="12" v-else>
          <div class="alert alert-danger">
            ท่านยังไม่ได้ระบุชั้น และห้อง
          </div>
        </CCol>
        <CCol lg="12" v-if="userData"> </CCol>
      </CRow>

    </div>

    <div v-else-if="$store.state.selectedSubjectMode == 2">
     
      <div v-if="this.$store.state.studentListSec">
        
        <div v-for="(std, n) in this.$store.state.studentListSec" :key="std.id" :id="'stdDivSec' + n"
          class="alert alert-success">
          ที่ {{ n + 1 }}: {{ std.stud_id }} {{ std.prefix_name }}{{ std.std_name }}
          {{ std.std_surname }} ({{ std.level_abv_name }}/{{ std.std_class }})
          <div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'rDo' + std.stud_id" :id="'rDo' + std.stud_id"
                :value="std.attType" checked @change="updateStdListSec(n, 1)" />
              <label class="form-check-label" :for="'rDo' + std.stud_id">มา</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'rDo' + std.stud_id" :id="'rDo' + std.stud_id"
                :value="std.attType" @change="updateStdListSec(n, 2)" />
              <label class="form-check-label" :for="'rDo' + std.stud_id">ลา</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'rDo' + std.stud_id" :id="'rDo' + std.stud_id"
                :value="std.attType" @change="updateStdListSec(n, 3)" />
              <label class="form-check-label" :for="'rDo' + std.stud_id">ขาด</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" :name="'rDo' + std.stud_id" :id="'rDo' + std.stud_id"
                :value="std.attType" @change="updateStdListSec(n, 4)" />
              <label class="form-check-label" :for="'rDo' + std.stud_id">หนีเรียน</label>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-info" v-else>
        ไม่พบรายชื่อ
      </div>

    </div>
    <div v-else-if="$store.state.selectedSubjectMode == 3">
      <div class="relative w-full mb-3" v-if="userData">
        <label class="
            block
            uppercase
            text-gray-700 text-xs
            font-bold
            mb-2
          " htmlFor="grid-password">
          นักเรียนที่ขาด
        </label>
        <SltStudent />
      </div>
      <div class="relative w-full mb-3">
        <label class="
            block
            uppercase
            text-gray-700 text-xs
            font-bold
            mb-2
          " htmlFor="grid-password">
          นักเรียนที่ลา
        </label>
        <SltStudent1 />
      </div>
    </div>
    <div v-else class="alert alert-danger">
      โปรดเลือกรูปแบบของรายชื่อ
    </div>
  </div>
</template>

<script>
import SltStudent from "./SltStudent";
import SltStudent1 from "./SltStudent1";
export default {
  components: {
    SltStudent,
    SltStudent1
  },
  props: ['type'],
  data() {
    return {
      userData: this.$cookies.get("user"),
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
    };
  },
  mounted() {

  },
  methods: {
    processStdList() {
      let leaveList = [];
      let missList = [];
      let fleeList = [];
      //PRECESS FROM REGULAR
      if (this.$store.state.studentList) {
        this.$store.state.studentList.forEach((element) => {

          if (element.attType == 2) {
            leaveList.push({
              code: element.stud_id,
              label:
                element.stud_id +
                " " +
                element.std_name +
                " " +
                element.std_surname +
                " " + ' (' +
                element.level_abv_name +
                "/" +
                element.std_class + ')'
            });
          } else if (element.attType == 3) {
            missList.push({
              code: element.stud_id,
              label:
                element.stud_id +
                " " +
                element.std_name +
                " " +
                element.std_surname +
                " " + ' (' +
                element.level_abv_name +
                "/" +
                element.std_class + ')'
            });
          }
          else if (element.attType == 4) {
            fleeList.push({
              code: element.stud_id,
              label:
                element.stud_id +
                " " +
                element.std_name +
                " " +
                element.std_surname +
                " " + ' (' +
                element.level_abv_name +
                "/" +
                element.std_class + ')'
            });
          }
        });
      }


      //PROCESS FROM ELECTIVE
      if (this.$store.state.studentListSec) {
        this.$store.state.studentListSec.forEach((element) => {

          if (element.attType == 2) {
            leaveList.push({
              code: element.stud_id,
              label:
                element.stud_id +
                " " +
                element.std_name +
                " " +
                element.std_surname +
                " " + ' (' +
                element.level_abv_name +
                "/" +
                element.std_class + ')'
            });
          } else if (element.attType == 3) {
            missList.push({
              code: element.stud_id,
              label:
                element.stud_id +
                " " +
                element.std_name +
                " " +
                element.std_surname +
                " " + ' (' +
                element.level_abv_name +
                "/" +
                element.std_class + ')'
            });
          }
          else if (element.attType == 4) {
            fleeList.push({
              code: element.stud_id,
              label:
                element.stud_id +
                " " +
                element.std_name +
                " " +
                element.std_surname +
                " " + ' (' +
                element.level_abv_name +
                "/" +
                element.std_class + ')'
            });
          }
        });
      }


      this.$store.commit("setSelectedStudent2", fleeList);
      this.$store.commit("setSelectedStudent1", leaveList);
      this.$store.commit("setSelectedStudent", missList);
    },
    updateStdListSec(rowIndex, attType) {
      if (attType == 1) {
        document.getElementById("stdDivSec" + rowIndex).className =
          "alert alert-success";
        this.$store.state.studentListSec[rowIndex].attType = attType;
        //console.log(this.$store.state.studentList[rowIndex]);
        this.processStdList();
      } else if (attType == 2) {
        document.getElementById("stdDivSec" + rowIndex).className =
          "alert alert-warning";
        this.$store.state.studentListSec[rowIndex].attType = attType;
        //console.log(this.$store.state.studentList[rowIndex]);
        this.processStdList();
      } else if (attType == 3) {
        document.getElementById("stdDivSec" + rowIndex).className =
          "alert alert-danger";
        this.$store.state.studentListSec[rowIndex].attType = attType;
        //console.log(this.$store.state.studentList[rowIndex]);
        this.processStdList();
      }
      else if (attType == 4) {
        document.getElementById("stdDivSec" + rowIndex).className =
          "alert alert-primary";
        this.$store.state.studentListSec[rowIndex].attType = attType;
        //console.log(this.$store.state.studentList[rowIndex]);
        this.processStdList();
      }
    },
    updateStdList(rowIndex, attType) {

      if (attType == 1) {
        document.getElementById("stdDiv" + rowIndex).className =
          "alert alert-success";
        this.$store.state.studentList[rowIndex].attType = attType;
        //console.log(this.$store.state.studentList[rowIndex]);
        this.processStdList();
      } else if (attType == 2) {
        document.getElementById("stdDiv" + rowIndex).className =
          "alert alert-warning";
        this.$store.state.studentList[rowIndex].attType = attType;
        //console.log(this.$store.state.studentList[rowIndex]);
        this.processStdList();
      } else if (attType == 3) {
        document.getElementById("stdDiv" + rowIndex).className =
          "alert alert-danger";
        this.$store.state.studentList[rowIndex].attType = attType;
        //console.log(this.$store.state.studentList[rowIndex]);
        this.processStdList();
      }
      else if (attType == 4) {
        document.getElementById("stdDiv" + rowIndex).className =
          "alert alert-primary";
        this.$store.state.studentList[rowIndex].attType = attType;
        //console.log(this.$store.state.studentList[rowIndex]);
        this.processStdList();
      }
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
