<template>
  <div class="relative w-full mb-3" v-if="criteriaInfo">
    
    <label
      class="block uppercase text-gray-700 text-xs font-bold mb-2"
      htmlFor="grid-password"
    >
      เกณฑ์การให้คะแนน
    </label>
    <v-select
      :options="criteriaInfo"
      v-model="$store.state.selectedCriteria"
      @input="mutateSelectedCliteria"
    >
      <template #search="{ attributes, events }">
        <input
          class="from-control ui-input-text"
          v-bind="attributes"
          v-on="events"
          placeholder=""
        />
      </template>
    </v-select>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
      modalAddCriteria: false,
      criteriaInfo: null,
      criteriaRow: null,
    };
  },
  mounted() {
    let formData = new FormData();
    formData.append("txtAction", "getCriteriaByUserType");
    formData.append("schoolCode", this.userData.user_address);
    let userType = "teacher";
    if (this.userData.user_level.search("adminHR") >= 0) {
      userType = "adminHR";
    }
    formData.append("userType", userType);

    //FETCH CITERIA DATA
    this.axios
      .post(this.$hostUrl + "php_action/criteriaAPI.php", formData)
      .then((response) => {
        console.log(response.data);
        this.criteriaInfo = response.data.mainData;
      });
  },
  methods: {
    mutateSelectedCliteria(value) {
      this.$store.commit("setSelectedCriteria", value);
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 20%;
}
</style>
