import Vue from 'vue'
import Vuex from 'vuex'
import * as customJs from "./customScript";
Vue.use(Vuex)

const state = {
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    userData: "i am here",
    teachingLogList: null,
    teacherList: null,
    subjectDropdown: null,
    studentDropDown: null,
    studentList: null,
    studentListSec: null,
    provinceDropDown: null,
    prefixDropDown: null,
    periodChk: null,
    standardChk: [{
            standardName: "มาตรฐานที่ 1 คุณภาพของผู้เรียน",
            subStandard: [
                { subStandardId: 11, subStandardName: "1.1 ผลสัมฤทธิ์ทางวิชาการของผู้เรียน" },
                { subStandardId: 12, subStandardName: "1.2 คุณลักษณะที่พึงประสงค์ของผู้เรียน" },
            ],
        },
        {
            standardName: "มาตรฐานที่ 2 กระบวนการบริหารและการจัดการ",
            subStandard: [
                { subStandardId: 21, subStandardName: "2.1 มีเป้าหมายวิสัยทัศน์และพันธกิจที่สถานศึกษากำหนดขัดเจน" },
                { subStandardId: 22, subStandardName: "2.2 มีระบบบริหารจัดการคุณภาพของสถานศึกษา" },
                { subStandardId: 23, subStandardName: "2.3 ดำเนินงานพัฒนาวิชาการที่เน้นคุณภาพผู้เรียนรอบด้านตามหลักสูตรสถานศึกษาและทุกกลุ่มเป้าหมาย" },
                { subStandardId: 24, subStandardName: "2.4 พัฒนาครูและบุคลากรให้มีความเชี่ยวชาญทางวิชาชีพ" },
                { subStandardId: 25, subStandardName: "2.5 จัดสภาพแวดล้อมทางกายภาพและสังคมที่เอื้อต่อการจัดการเรียนรู้อย่างมีคุณภาพ" },
                { subStandardId: 26, subStandardName: "2.6 จัดระบบเทคโนโลยีสารสนเทศเพื่อสนับสนุนการบริหารจัดการและการจัดการเรียนรู้" },
            ],
        },
        {
            standardName: "มาตรฐานที่ 3 กระบวนการจัดการเรียนการสอนที่เน้นผู้เรียนเป็นสำคัญ",
            subStandard: [
                { subStandardId: 31, subStandardName: "3.1 จัดการเรียนรู้ผ่านกระบวนการคิดและปฏิบัติจริงและสามารถนำไปประยุกต็ใช้ในชีวิตได้" },
                { subStandardId: 32, subStandardName: "3.2 ใช้สื่อเทคโนโลยีสารสนเทศและแหล่งเรียนรู้ที่เอื้อต่อการเรียนรู้" },
                { subStandardId: 33, subStandardName: "3.3 มีการบริหารจัดการชั้นเรียนเชิงบวก" },
                { subStandardId: 34, subStandardName: "3.4 ตรวจสอบและประเมินผู้เรียนอย่างเป็นระบบและนำผลมาพัฒนาผู้เรียน" },
                { subStandardId: 35, subStandardName: "3.5 มีการแลกเปลียนเรียนรู้และให้ข้อมูลสะท้อนกลับเพื่อพัฒนาและปรับปรุงการจัดการเรียนรู้" },
            ],
        },
    ],
    selectedSubject: '',
    selectedStudent: '',
    selectedStudent1: '',
    selectedStudent2: '',
    selectedTeachingLog: null,
    selectedProvince: null,
    selectedDistrict: null,
    selectedCriteria: null,
    selectedPrefix: null,
    selectedLevel: null,
    selectedClass: null,
    selectedAsset: "",
    selectedBatch: "",
    selectedAcadYear: "",
    selectedAspect: "",
    selectedPosition: "",
    selectedAcademicStanding: "",
    selectedGarbage: "",
    selectedDepartment: "",
    selectedSemester: "",
    selectedRewardType: "",
    selectedRewardLevel: "",
    selectedDegree: "",
    selectedSubjectMode: null,
    checkedPeriod: [],
    checkedSubStandard: [],
    activeSemester: null,
    kJs: customJs.default,
    screeningLogList: null,
    semesterList: null,
    totalStudent: 0,
    colorControl: { red: '#DC2328', yellow: '#f7cc29', green: '#16AD50' },
    bgColor: [
        '#2A6478',
        '#1D334A',
        '#F44611',
        '#6C7156',
        '#9DA1AA',
        '#F4A900',
        '#6C6874',
        '#C7B446',
        '#C51D34',
        '#F4A900',
        '#828282',
        '#E6D690',
        '#193737',
    ],
    classNo: [],
    levelArr: ['ม.1', 'ม.2', 'ม.3', 'ม.4', 'ม.5', 'ม.6'],
    coords: [],
    districtInfo:null,
    

}

const mutations = {
    toggleSidebarDesktop(state) {
        const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile(state) {
        const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
        state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set(state, [variable, value]) {
        state[variable] = value
    },
    setUserData(state, newData) {
        state.userData = newData;
    },
    setTeachingLogList(state, newData) {
        state.teachingLogList = newData;
    },
    setTeacherList(state, newData) {
        state.teacherList = newData;
    },
    setSubjectDropdown(state, newData) {
        state.subjectDropdown = newData;
    },
    setStudentDropdown(state, newData) {
        state.studentDropdown = newData;
    },
    setStudentList(state, newData) {
        state.studentList = newData;
    },
    setStudentListSec(state, newData) {
        state.studentListSec = newData;
    },

    setProvinceDropdown(state, newData) {
        state.provinceDropdown = newData;
    },
    setPrefixDropdown(state, newData) {
        state.prefixDropdown = newData;
    },
    setPeriodChk(state, newData) {
        state.periodChk = newData;
    },
    setSelectedSubject(state, newData) {
        state.selectedSubject = newData;
    },
    setSelectedStudent(state, newData) {
        state.selectedStudent = newData;
    },
    setSelectedStudent1(state, newData) {
        state.selectedStudent1 = newData;
    },
    setSelectedStudent2(state, newData) {
        state.selectedStudent2 = newData;
    },
    setSelectedTeachingLog(state, newData) {
        state.selectedTeachingLog = newData;
    },
    setSelectedProvince(state, newData) {
        state.selectedProvince = newData;
    },
    setSelectedDistrict(state, newData) {
        state.selectedDistrict = newData;
    },

    setSelectedCriteria(state, newData) {
        state.selectedCriteria = newData;
    },
    setSelectedPrefix(state, newData) {
        state.selectedPrefix = newData;
    },

    setSelectedLevel(state, newData) {
        state.selectedLevel = newData;
    },
    setSelectedAsset(state, newData) {
        state.selectedAsset = newData;
    },
    setSelectedClass(state, newData) {
        state.selectedClass = newData;
    },
    setSelectedBatch(state, newData) {
        state.selectedBatch = newData;
    },
    setSelectedAcadYear(state, newData) {
        state.selectedAcadYear = newData;
    },
    setSelectedAspect(state, newData) {
        state.selectedAspect = newData;
    },
    setSelectedPosition(state, newData) {
        state.selectedPosition = newData;
    },
    setSelectedAcademicStanding(state, newData) {
        state.selectedAcademicStanding = newData;
    },
    setSelectedGarbage(state, newData) {
        state.selectedGarbage = newData;
    },
    setSelectedDepartment(state, newData) {
        state.selectedDepartment = newData;
    },
    setSelectedSemester(state, newData) {
        state.selectedSemester = newData;
    },
    setSelectedRewardType(state, newData) {
        state.selectedRewardType = newData;
    },
    setSelectedRewardLevel(state, newData) {
        state.selectedRewardLevel = newData;
    },
    setSelectedDegree(state, newData) {
        state.selectedDegree = newData;
    },
    setSelectedSubjectMode(state, newData) {
        state.selectedSubjectMode = newData;
    },
    setCheckedPeriod(state, newData) {
        state.checkedPeriod = newData;
    },
    setCheckedSubStandard(state, newData) {
        state.checkedSubStandard = newData;
    },
    setActiveSemester(state, newData) {
        state.activeSemester = newData;
    },
    setScreeningLogList(state, newData) {
        state.screeningLogList = newData;
    },

    setTotalStudent(state, newData) {
        state.totalStudent = newData;
    },
    setTotalDeposit(state, newData) {
        state.totalDeposit = newData;
    },
    setCoords(state, newData) {
        state.coords = newData;
    },
    setDistrictInfo(state, newData) {
        state.districtInfo = newData;
    },


}

for (let i = 1; i <= 20; i++) {
    state.classNo.push(i);
}

export default new Vuex.Store({
    state,
    mutations
})