<template>
  <div class="relative w-full mb-3" v-if="$store.state.periodChk">
    <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
      คาบ: {{$store.state.checkedPeriod.length}} คาบ
    </label>
    <div class="form-check" v-for="period in $store.state.periodChk" :key="period.id">
      <input class="form-check-input" type="checkbox" name="period[]" :value="period.period_id"
        :id="modalType + 'flexCheck' + period.period_id" v-model="$store.state.checkedPeriod" />
      <label class="form-check-label" :for="'flexCheckDefault' + period.period_id">
        {{ period.period_name }} ({{ period.period_start }}-{{ period.period_end }} น.)
      </label>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  props: {
    modalType: String
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
    };
  },
  mounted() {
    

    //FETCH PERIOD DATA
    let formData = new FormData();
    formData.append('txtAction', 'getPeriodBySchool')
    formData.append('schoolCode', this.userData.user_address)
    this.axios
      .post(this.$hostUrl + "php_action/periodAPI.php", formData)
      .then((response) => {
        //console.log(response.data);
        this.$store.commit("setPeriodChk", response.data.mainData);

      });


  },
  methods: {
    sendData() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateAbsense");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("selectedSemester", this.selectedSemester.code);
      formData.append("selectedSubject", this.selectedSubject.code);
      formData.append("txtReason", this.selectedCiteria.code);
      let jsonArr = JSON.stringify(this.selectedStudent);
      formData.append("selectedStudent", jsonArr);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == "") {
            this.showModal = true;
            this.modalSuccess = true;
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            this.showModal = true;
            this.modalSuccess = false;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => { });
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => { });
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
  width: 0px;
}
</style>
