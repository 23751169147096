<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  mounted() {

  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
//@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap");

@font-face {
  font-family: "Baijam";
  src: local("Baijam"), url(./assets/fonts/BaiJamjuree-Regular.ttf) format("truetype");
}
body {
  font-family: "Baijam", sans-serif;
  font-size: 16px;
}
</style>