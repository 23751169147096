<template>
  <CRow>
    <CCol lg="12">
      <div class="text-right">
        <button class="btn btn-dark" @click="printD">
          <i class="ri-printer-line ri-lg"></i>
        </button></div
    ></CCol>

    <CCol lg="12" class="bg-white text-black" id="single-info"
      ><table class="table">
        <tbody v-if="rewardData">
          <tr>
            <td colspan="8">
              <div class="text-center"><h1>ผลงานที่เป็นเลิศ</h1></div>
            </td>
          </tr>
          <tr>
            <td colspan="2" width="10%">
              <div>ชื่อผลงาน:</div>
            </td>
            <td colspan="2" >
              <div>
                {{ rewardData.reward_name }}
              </div>
            </td>
            <td colspan="2" width="20%">
              <div>มาตรฐานที่เกี่ยวข้อง:</div>
            </td>
            <td colspan="2" width="20%">
              <div>
                {{ rewardData.reward_standard }}
              </div>
            </td>
          </tr>

          <tr>
            <td colspan="2">
              <div>รายละเอียด:</div>
            </td>
            <td colspan="2">
              <div>
                {{ rewardData.reward_description }}
              </div>
            </td>
            <td colspan="2">
              <div>วันที่ได้รับรางวัล:</div>
            </td>
            <td colspan="2">
              <div>{{rewardData.reward_date}}</div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div>ผู้ให้รางวัล: {{rewardData.reward_owner}}</div>
            </td>
          </tr>
          <tr>
            <td colspan="8">
              <div>ภาพประกอบ:</div>
              <div class="text-center"> <img :src="$hostUrl+rewardData.reward_pic" width="80%"/></div>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div>ประเภท:</div>
            </td>
            <td colspan="2">
              <div>
                {{ rewardData.reward_typ_name }}
              </div>
            </td>
            <td colspan="2">
              <div>ระดับ:</div>
            </td>
            <td colspan="2">
              <div>
                {{ rewardData.reward_level_name }}
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="4">
            </td>
            <td colspan="4" class="text-center" >
              <div style="margin-top:50px">({{ rewardData.user_fname }} {{ rewardData.user_lname }})</div>
              <div>ผู้บันทึก</div>
            </td>
          </tr>
        </tbody>
      </table>
    </CCol>
  </CRow>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import { Printd } from "printd";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    Printd,
  },
  data() {
    return {
      userId: this.$cookies.get("user").user_id,
      responseContent: null,
      darkModal: false,
      exportingData: null,
      teacherInfo: null,
      selectedTeacher: null,
      userData: this.$cookies.get("user"),
      teachingLogInfo: null,
      txtComment: null,
      cssText: `
      @font-face {
  font-family: "Sarabun";
  src: local("Sarabun"), url(./assets/fonts/Sarabun-Light.ttf) format("truetype");
}
h1 h4 {
    color: black;
    font-family: Sarabun;
  }
  .text-red{
    color: red;
  }
.table th,
.table td {
    vertical-align: top;
    font-size: 0.7rem;
    font-family: Sarabun;
}
table{
  width:100% !important;
}
.sign tr{
  border: none !important;
}
.bg-red{
    background-color: #ef5550;
}
h4,
.h4 {
    font-size: 1.25rem
}
h5,
.h5 {
    font-size: 1.0rem
}
h3,
.h3 {
    font-size: 1.5rem;
    font-family: Sarabun
}
.text-right {
    text-align: right !important
}
.text-center {
    text-align: center !important;
    font-family: Sarabun;
}

`,
      rewardData: null,
    };
  },
  mounted() {
    //FETCH SELECTED REWARD
    let formData = new FormData();
    formData.append('txtAction', 'getRewardData');
    formData.append('rewardId', this.$route.params.rewardId);
    this.axios
      .post(
        this.$hostUrl +
          "php_action/rewardAPI.php", formData
      )
      .then((response) => {
        //console.log(response.data);
        this.rewardData = response.data.mainData;
      });
  },
  methods: {
    updateComment(event) {
      //SEND DATA
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "updateComment");
      this.axios
        .post(this.$hostUrl + "php_action/teachingAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            this.responseContent = response.data.error;
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },

    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    updateTableForTeacher() {
      let teacherId = this.selectedTeacher.code;

      //FETCH Semester Stats BY TEACHER
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=getStatByTeacher&selectedSemester=" +
            this.$route.params.semesterId +
            "&teacherId=" +
            teacherId
        )
        .then((response) => {
          this.rows = response.data.mainData;
          if (this.rows == undefined) {
            this.rows = [{}];
          }
        })
        .finally(() => {});
    },
    printD() {
      //const cssText = this.cssText;
      const cssText = this.cssText;
      const d = new Printd();
      d.print(document.getElementById("single-info"), [cssText]);
    },
  },
};
</script>

<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
