<template>
  <CRow class="justify-content-center" style="margin-top: 0px">
    <CCol
      lg="6"
      style="padding: 20px"
      v-if="nowLat"
      class="text-center text-white bg-pink"
    >
      <h1>ละติจูดปัจจุบัน: <br />{{ nowLat }}</h1>
    </CCol>
    <CCol
      lg="6"
      style="padding: 20px"
      v-if="nowLat"
      class="text-center text-white bg-violet"
    >
      <h1>ลองจิจูดปัจจุบัน: <br />{{ nowLng }}</h1>
    </CCol>
    <CCol
      lg="12"
      style="padding: 20px"
      v-if="nowLat"
      class="text-center text-white bg-warning"
    >
      <CRow>
        <CCol col="12" class="text-center">
          <CButtonGroup size="lg">
            <CButton color="primary" @click="createAttendance('in')"
              >ลงเวลาเข้า</CButton
            >
            <CButton color="danger" @click="createAttendance('out')"
              >ลงเวลาออก</CButton
            >
          </CButtonGroup>
        </CCol>
      </CRow>
    </CCol>
    <TblAttendanceByDate/>
    <!--- MODAL AREA-->
    <CModal
      :show.sync="modalAlert"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="danger"
    >
      {{responseContent}}
      <template #header>
        <h6 class="modal-title">คำเตือน!!</h6>
        <CButtonClose @click="modalAlert = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="modalAlert = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </CRow>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx
import TblAttendanceByDate from "./components/TblAttendanceByDate";

export default {
  name: "Dashboard",
  components: { VueGoodTable, Datepicker, vSelect, XLSX , TblAttendanceByDate},
  data() {
    return {
      userData: this.$cookies.get("user"),
      showModal: false,
      selectedData: null,
      citeriaInfo: null,
      improveInfo: null,
      selectedCiteria: null,
      selectedAppointment: null,
      selectedStartDate: null,
      selectedEndDate: null,
      responseContent: null,
      darkModal: false,
      modalAlert: false,
      msgAlert: null,
      nowLat: null,
      nowLng: null,
    };
  },
  mounted() {
    //GET LOCATION
    navigator.geolocation.getCurrentPosition(this.showPosition);

    //CHECK IS LOGED IN
    if (this.userData == null) {
      alert("please login");
      this.$router.push({ path: "/pages/login" });
    }
  },
  methods: {
    removeApp(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/appointmentAPI.php?txtAction=delete&appId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.deleteState == true) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    getAppByDate(event) {
      if (this.selectedStartDate == null || this.selectedEndDate == null) {
        this.msgAlert = "ต้องเลือกทั้งวันเริ่มต้น และวันสิ้นสุดของการค้นหา";
        this.modalAlert = true;
        return false;
      }
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "getAppointmentByDate");
      formData.append("schoolId", this.userData.school_code);
      this.axios
        .post(this.$hostUrl + "php_action/appointmentAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.rows = response.data.mainData;
          this.rowsExcel = response.data.excelData;
        })
        .finally(() => {});
    },
    showPosition(position) {
      console.log(position.coords.latitude);
      console.log(position.coords.longitude);
      this.nowLat = position.coords.latitude;
      this.nowLng = position.coords.longitude;
    },
    createAttendance(data) {
      //alert(data);
      if(this.nowLat == null || this.nowLng == null){
        return false;
      }
      var targetUrl = "";
      if (data == "in") {
        targetUrl =
          this.$hostUrl +
          "php_action/checkinAPI.php?txtAction=createWFHin&userId=" +
          this.userData.user_id +
          "&lat=" +
          this.nowLat +
          "&lng=" +
          this.nowLng;
      } else if (data == "out") {
        targetUrl =
          this.$hostUrl +
          "php_action/checkinAPI.php?txtAction=createWFHout&userId=" +
          this.userData.user_id +
          "&lat=" +
          this.nowLat +
          "&lng=" +
          this.nowLng;
      }
      //SEND DATA
      this.axios
        .get(targetUrl)
        .then((response) => {
          console.log(response.data);
          if (response.data.createState == "success") {
            alert("ลงเวลาสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.createState;
            //alert(this.responseContent);
            this.modalAlert = true;
          }
        })
        .finally(() => {});
    },
  },
};
</script>
