<template>
  <div class="relative w-full mb-3">
    <label
      class="block uppercase text-gray-700 text-xs font-bold mb-2"
      htmlFor="grid-password"
    >
      เลือกระดับชั้น:
    </label>
    <v-select
      v-if="levelInfo"
      :options="levelInfo"
      v-model="$store.state.selectedLevel"
      @input="mutateSelectedLevel"
    >
      <template #search="{ attributes, events }">
        <input
          class="from-control ui-input-text"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>
    <div v-else>loading...</div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
      levelInfo: null,
    };
  },
  mounted() {
    //FETCH LEVEL
    this.axios
      .get(this.$hostUrl + "php_action/levelAPI.php?txtAction=getAll")
      .then((response) => {
        //console.log(response.data);
        this.levelInfo = response.data.mainData;
      });
  },
  methods: {
    mutateSelectedLevel(value) {
      this.$store.commit("setSelectedLevel", value);
      this.updateStudentList();
    },
    updateStudentList() {
      //FETCH STUDENT LIST
      let formData = new FormData();
      formData.append("txtAction", "getStdByLevelClassSchool");
      formData.append("levelCode", this.$store.state.selectedLevel.code);
      formData.append("class", this.$store.state.selectedClass);
      formData.append("schoolCode", this.userData.user_address);
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response);
        });
    },
    mutateSelectedDistrict(value) {
      this.$store.commit("setSelectedDistrict", value);

    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => {});
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 20%;
}
</style>
