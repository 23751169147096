<template>
  <div>
    <div>ประเภทใบรายชื่อ:</div>
    <div v-if="type == 'basic-search'">
      <div class="button">
        <input type="radio" id="a25" name="check-substitution-2" value="1" v-model="$store.state.selectedSubjectMode" />
        <label class="btn btn-default" for="a25">ห้อง</label>
      </div>
      <div class="button">
        <input type="radio" id="a50" name="check-substitution-2" value="3" v-model="$store.state.selectedSubjectMode" />
        <label class="btn btn-default" for="a50">ค้นหา</label>
      </div>
    </div>
    <div v-else>
      <div class="button">
        <input type="radio" id="a25" name="check-substitution-2" value="1" v-model="$store.state.selectedSubjectMode" />
        <label class="btn btn-default" for="a25">ห้อง</label>
      </div>
      <div class="button">
        <input type="radio" id="a51" name="check-substitution-3" value="2" v-model="$store.state.selectedSubjectMode" />
        <label class="btn btn-default" for="a51">เพิ่มเติม</label>
      </div>
      <div class="button">
        <input type="radio" id="a50" name="check-substitution-2" value="3" v-model="$store.state.selectedSubjectMode" />
        <label class="btn btn-default" for="a50">ค้นหา</label>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {


  },
  props: ['type'],
  data() {
    return {
      userData: this.$cookies.get("user"),
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
    };
  },
  mounted() {

  },
  methods: {
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-color: transparent;
}
</style>
