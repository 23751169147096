<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol>
          <CCard v-if="rows">
            <CCardHeader class="bg-dark text-white"
              ><strong>สรุปบันทึกคะแนนความประพฤติของ</strong>
              {{ rows[0].std_id }} {{ rows[0].std_name }}
              {{ rows[0].std_surname }}<br />

              <h3 class="text-warning">
                <strong>คะแนนที่เหลือ: {{ grandTotal }} คะแนน</strong>
              </h3>
            </CCardHeader>
            <CCardBody>
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :search-options="{ enabled: true }"
                :sort-options="{
                  enabled: true,
                  initialSortBy: { field: 'att_id', type: 'desc' },
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'page',
                  perPage: 10,
                }"
              >
                >
                <template slot="table-row" slot-scope="props"> </template>
              </vue-good-table>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow class="justify-content-center">
        <CCol md="12" class="text-right">
          พัฒนาโดย ดร.เจตนิพิฐ  แท่นทอง
        </CCol>
      </CRow>
    </CContainer>
    <CModal
      :show.sync="darkModal"
      :no-close-on-backdrop="true"
      :centered="true"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      password หรือ username ไม่ถูกต้อง
      <template #header>
        <h6 class="modal-title">คำเตือน!!</h6>
        <CButtonClose @click="darkModal = false" class="text-white" />
      </template>
      <template #footer>
        <CButton @click="darkModal = false" color="danger">Discard</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
export default {
  name: "Login",
  components: { VueGoodTable },
  data() {
    return {
      darkModal: false,

      columns: [
        {
          label: "เลขที่",
          field: "att_id",
          type: "number",
        },
        {
          label: "วันที่",
          field: "abs_date",
        },
        {
          label: "คำนำหน้า",
          field: "std_title",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "วิชา/กิจกรรม/สถานที่",
          field: "subject_name",
        },
        {
          label: "ผู้รายงาน",
          field: "user_fname",
        },
        {
          label: "ประเภท",
          field: "tc_citeria_name",
        },
        {
          label: "คะแนน",
          field: "tc_citeria_score",
        },
      ],
      rows: null,
      grandTotal: null,
    };
  },
  mounted() {
    let stdId = this.$route.params.stdId;
    let schoolId = this.$route.params.schoolId;

    //FETCH STD STAT
    this.axios
      .get(
        this.$hostUrl +
          "php_action/userAPI.php?txtAction=getStdWithSchoolFilter&stdId="+stdId+"&schoolId="+schoolId
      )
      .then((response) => {
        console.log(response.data);
          if (response.data.error == false && response.data.mainData) {
            
            if (response.data.mainData.length > 0) {
              //login success and show data
              this.rows = response.data.mainData;
              // Cal sum
              let sum = 0;
              console.log(this.rows[0].tc_citeria_score);
              for (let i = 0; i <= this.rows.length - 1; i++) {
                console.log(this.rows[i].tc_citeria_score);
                sum = sum + parseInt(this.rows[i].tc_citeria_score);
              }
              this.grandTotal = 100 + sum;
            }
            else{
              alert("ยินดีด้วยคะแนนพฤติกรรมยังเต็ม 100 คะแนน");
            }
          } else if (response.data.error == "") {
            //alert("here");
            //login fail
            this.darkModal = true;
          }
      })
      .finally(() => {});
  },
  methods: {
    searchBeh() {
      this.uploadingStat = 1;
      const form = document.getElementById("formSearchBeh");
      const formData = new FormData(form);
      formData.append("txtAction", "getBehScore");
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false && response.data.mainData) {
            
            if (response.data.mainData.length > 0) {
              //login success and show data
              this.rows = response.data.mainData;
              // Cal sum
              let sum = 0;
              console.log(this.rows[0].tc_citeria_score);
              for (let i = 0; i <= this.rows.length - 1; i++) {
                console.log(this.rows[i].tc_citeria_score);
                sum = sum + parseInt(this.rows[i].tc_citeria_score);
              }
              this.grandTotal = 100 + sum;
            }
            else{
              alert("ยินดีด้วยคะแนนพฤติกรรมยังเต็ม 100 คะแนน");
            }
          } else if (response.data.error == "") {
            //alert("here");
            //login fail
            this.darkModal = true;
          }
        })
        .finally(() => {});
    },
  },
  computed: {},
};
</script>
