import Vue from 'vue'
import Router from 'vue-router'

import StatBySemester from "../views/StatBySemester";
import StatByDate from "../views/StatByDate";
import StatByStudent from "../views/StatByStudent";
import StatBySubject from "../views/StatBySubject";
import StatByTeacher from "../views/StatByTeacher";
import StatByMonth from "../views/StatByMonth";
import StatByReason from "../views/StatByReason";
import SumScore from "../views/SumScore";
import SearchBehScore from "../views/pages/SearchBehScore";
import ReportByStd from "../views/pages/ReportByStd";
import AppStatAll from "../views/AppStatAll";
import WFH from "../views/WFH";
import TeachingLog from "../views/TeachingLog";
import TeachingLogView from "../views/TeachingLogView";
import RewardView from "../views/RewardView";




// Containers
const TheContainer = () =>
    import ('@/containers/TheContainer')
const PublicDashBoard = () =>
    import ('@/containers/PublicDashboard')

// Views
const CreateTeachingLog = () =>
    import ('@/views/CreateTeachingLog')
const Dashboard = () =>
    import ('@/views/Dashboard')
const DashboardTutionFee = () =>
    import ('@/views/DashboardTutionFee')
const DashboardTeacher = () =>
    import ('@/views/DashboardTeacher');
const DashboardLittleBank = () =>
    import ('@/views/DashboardLittleBank');
const DashboardRewards = () =>
    import ('@/views/DashboardRewards');
const DashboardAdmin = () =>
    import ('@/views/DashboardAdmin');
const DashboardUploadScore = () =>
    import ('@/views/DashboardUploadScore');
const DashboardFrequency = () =>
    import ('@/views/DashboardFrequency');
const DashboardMyHistory = () =>
    import ('@/views/DashboardMyHistory');
const DashboardQuicklyReset = () =>
    import ('@/views/DashboardQuicklyReset');
const UploadTransaction = () =>
    import ('@/views/UploadTransaction')
const StatLateByDate = () =>
    import ('@/views/StatLateByDate')
const AllowExit = () =>
    import ('@/views/AllowExit')
const UserProfile = () =>
    import ('@/views/UserProfile')
const UserProfileView = () =>
    import ('@/views/UserProfileView')
const TeacherProfile = () =>
    import ('@/views/TeacherProfile')
const RecordMain = () =>
    import ('@/views/RecordMain')
const SubdistrictMain = () =>
    import ('@/views/SubdistrictMain')
const StatInvoiceByClass = () =>
    import ('@/views/StatInvoiceByClass')


const Colors = () =>
    import ('@/views/theme/Colors')


//REPORTS
const MainReport = () =>
    import ('@/views/reports/MainReport')
const ClassMissing = () =>
    import ('@/views/reports/ClassMissing')

const Typography = () =>
    import ('@/views/theme/Typography')

const Charts = () =>
    import ('@/views/charts/Charts')
const Widgets = () =>
    import ('@/views/widgets/Widgets')

// Views - Components
const Cards = () =>
    import ('@/views/base/Cards')
const Forms = () =>
    import ('@/views/base/Forms')
const Switches = () =>
    import ('@/views/base/Switches')
const Tables = () =>
    import ('@/views/base/Tables')
const Tabs = () =>
    import ('@/views/base/Tabs')
const Breadcrumbs = () =>
    import ('@/views/base/Breadcrumbs')
const Carousels = () =>
    import ('@/views/base/Carousels')
const Collapses = () =>
    import ('@/views/base/Collapses')
const Jumbotrons = () =>
    import ('@/views/base/Jumbotrons')
const ListGroups = () =>
    import ('@/views/base/ListGroups')
const Navs = () =>
    import ('@/views/base/Navs')
const Navbars = () =>
    import ('@/views/base/Navbars')
const Paginations = () =>
    import ('@/views/base/Paginations')
const Popovers = () =>
    import ('@/views/base/Popovers')
const ProgressBars = () =>
    import ('@/views/base/ProgressBars')
const Tooltips = () =>
    import ('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () =>
    import ('@/views/buttons/StandardButtons')
const ButtonGroups = () =>
    import ('@/views/buttons/ButtonGroups')
const Dropdowns = () =>
    import ('@/views/buttons/Dropdowns')
const BrandButtons = () =>
    import ('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () =>
    import ('@/views/icons/CoreUIIcons')
const Brands = () =>
    import ('@/views/icons/Brands')
const Flags = () =>
    import ('@/views/icons/Flags')

// Views - Notifications
const Alerts = () =>
    import ('@/views/notifications/Alerts')
const Badges = () =>
    import ('@/views/notifications/Badges')
const Modals = () =>
    import ('@/views/notifications/Modals')

// Views - Pages
const Page404 = () =>
    import ('@/views/pages/Page404')
const Page500 = () =>
    import ('@/views/pages/Page500')
const Login = () =>
    import ('@/views/pages/Login')
const LoginStudent = () =>
    import ('@/views/pages/LoginStudent')
const LoginTeacher = () =>
    import ('@/views/pages/LoginTeacher')
const Register = () =>
    import ('@/views/pages/Register')
const WFHCheckIn = () =>
    import ('@/views/pages/WFHCheckIn')
const FaceCheckIn = () =>
    import ('@/views/pages/FaceCheckIn')
const DiscussionView = () =>
    import ('@/views/pages/DiscussionView')
const ReportUpdatedRecord = () =>
    import ('@/views/pages/ReportUpdatedRecord')
const DashboardCOVID = () =>
    import ('@/views/DashboardCOVID')
const DashboardInformation = () =>
    import ('@/views/DashboardInformation')
const BookMeeting = () =>
    import ('@/views/pages/BookMeeting')
const Auth = () =>
    import ('@/views/pages/Auth')


// Users
const Users = () =>
    import ('@/views/users/Users')
const User = () =>
    import ('@/views/users/User')

//ADMIN PAGE
const StatByRisk = () =>
    import ('@/views/StatByRisk');

Vue.use(Router)

export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
    base: process.env.BASE_URL
})

function configRoutes() {
    return [{
            path: '/publicDashboard/:schoolCode',
            name: 'PublicDashBoard',
            component: PublicDashBoard,
        },
        {
            path: '/',
            redirect: '/dashboard',
            name: 'Home',
            component: TheContainer,
            children: [{
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard
                },
                {
                    path: 'createTeachingLog/:subjectId',
                    name: 'ลงบันทึกการสอน',
                    component: CreateTeachingLog
                },
                {
                    path: 'dashboardTutionFee',
                    name: 'หน้าหลักระบบค่าบำรุงการศึกษา',
                    component: DashboardTutionFee
                },
                {
                    path: 'dashboardTeacher',
                    name: 'หน้าหลักระบบครู',
                    component: DashboardTeacher
                },
                {
                    path: 'dashboardInformation',
                    name: 'หน้าหลักระบบสารสนเทศโรงเรียน',
                    component: DashboardInformation
                },
                {
                    path: 'dashboardLittleBank',
                    name: 'หน้าหลักงานธนาคารขยะ',
                    component: DashboardLittleBank
                },
                {
                    path: 'dashboardUploadScore',
                    name: 'จัดการคะแนนรายวิชา',
                    component: DashboardUploadScore
                },
                {
                    path: 'dashboardQuicklyReset',
                    name: 'หน้าหลักแก้ไขพฤติกรรมแบบด่วน',
                    component: DashboardQuicklyReset
                },
                {
                    path: 'rewards',
                    name: 'หน้าหลักผลงานโรงเรียน',
                    component: DashboardRewards
                },
                {
                    path: 'dashboardAdmin',
                    name: 'หน้าหลักผู้บริหาร',
                    component: DashboardAdmin
                },
                {
                    path: 'dashboardFrequency',
                    name: 'สรุปความถี่พฤติกรรม',
                    component: DashboardFrequency
                },
                {
                    path: 'dashboardMyHistory',
                    name: 'ประวัติการทำรายการ',
                    component: DashboardMyHistory
                },
                {
                    path: 'uploadTransaction',
                    name: 'อัพโหลดไฟล์จากธนาคาร',
                    component: UploadTransaction
                },
                {
                    path: 'statBySemester/:semesterId',
                    name: 'สรุปรายการประจำภาคเรียน',
                    component: StatBySemester
                },
                {
                    path: 'statByMonth',
                    name: 'สรุปรายการประจำเดือน',
                    component: StatByMonth
                },
                {
                    path: 'statByDate/:semesterId/:date',
                    name: 'สรุปรายการประจำวัน',
                    component: StatByDate
                },
                {
                    path: 'statByStudent/:studentId',
                    name: 'สรุปรายบุคคล',
                    component: StatByStudent
                },
                {
                    path: 'statBySubject/:subjectId',
                    name: 'สรุปรายวิชา/ กิจกรรม/ สถานที่',
                    component: StatBySubject
                },
                {
                    path: 'statByTeacher/:teacherId',
                    name: 'สรุปรายการของผู้แจ้ง',
                    component: StatByTeacher
                },
                {
                    path: 'statByReason/:reasonId',
                    name: 'สรุปรายการของประเภท',
                    component: StatByReason
                },

                {
                    path: 'sumScore',
                    name: 'สรุปคะแนน',
                    component: SumScore
                },
                {
                    path: 'appStatAll',
                    name: 'สรุปรายการนัดหมายปรับปรุงคะแนนพฤติกรรม',
                    component: AppStatAll
                },
                {
                    path: 'wfh',
                    name: 'ลงเวลาปฏิบัติงานนอกสถานที่',
                    component: WFH
                },
                {
                    path: 'faceCheckIn',
                    name: 'สแกนใบหน้าลงเวลา',
                    component: FaceCheckIn
                },
                {
                    path: 'teachingLog',
                    name: 'รายงานการสอน',
                    component: TeachingLog
                },
                {
                    path: 'teachingLogView/:logId',
                    name: 'รายงานการสอนรายคาบ',
                    component: TeachingLogView
                },
                {
                    path: 'rewardView/:rewardId',
                    name: 'ผลงานเด่น',
                    component: RewardView
                },
                {
                    path: 'statLateByDate',
                    name: 'สรุปนักเรียนมาสายประจำวัน',
                    component: StatLateByDate
                },
                {
                    path: 'allowExit',
                    name: 'อนุมัติการออกนอกสถานศึกษา',
                    component: AllowExit
                },
                {
                    path: 'userProfile',
                    name: 'Profile',
                    component: UserProfile
                },
                {
                    path: 'userProfileView/:stdId/:schoolCode',
                    name: 'ระเบียนประวัติรายบุคคล',
                    component: UserProfileView
                },
                {
                    path: 'teacherProfile',
                    name: 'profile ครู',
                    component: TeacherProfile
                },
                {
                    path: 'statInvoiceByClass/:levelCode/:class',
                    name: 'รายการใบแจ้งหนี้จำแนกรายห้อง',
                    component: StatInvoiceByClass
                },
                {
                    path: 'dashboardCOVID',
                    name: 'แผงจัดการ COVID WAR ROOM',
                    component: DashboardCOVID
                },
                {
                    path: 'statByRisk',
                    name: 'นักเรียนเสี่ยงไม่จบการศึกษา',
                    component: StatByRisk
                },
                {
                    path: 'reports',
                    redirect: '/reports/mainReport',
                    name: 'รายงาน',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'mainReport',
                            name: 'รายงานต่างๆ',
                            component: MainReport
                        },
                        {
                            path: 'classMissing',
                            name: 'สรุปจำนวนนักเรียนขาดเรียน',
                            component: ClassMissing
                        },
                        {
                            path: 'typography',
                            name: 'Typography',
                            component: Typography
                        }
                    ]
                },
                {
                    path: 'records',
                    redirect: '/records/main',
                    name: 'ระเบียนประวัติ',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'main',
                            name: 'หน้าหลัก',
                            component: RecordMain
                        },
                        {
                            path: 'subdistrict',
                            name: 'ตำบลที่พักอาศัยของนักเรียน',
                            component: SubdistrictMain
                        }
                    ]
                },
                {
                    path: 'theme',
                    redirect: '/theme/colors',
                    name: 'Theme',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                        path: 'colors',
                        name: 'Colors',
                        component: Colors
                    }, ]
                },
                {
                    path: 'charts',
                    name: 'Charts',
                    component: Charts
                },
                {
                    path: 'widgets',
                    name: 'Widgets',
                    component: Widgets
                },
                {
                    path: 'users',
                    meta: {
                        label: 'Users'
                    },
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [{
                            path: '',
                            name: 'บุคลากร',
                            component: Users
                        },
                        {
                            path: ':id',
                            meta: {
                                label: 'User Details'
                            },
                            name: 'User',
                            component: User
                        }
                    ]
                },
                {
                    path: 'base',
                    redirect: '/base/cards',
                    name: 'Base',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'cards',
                            name: 'Cards',
                            component: Cards
                        },
                        {
                            path: 'forms',
                            name: 'Forms',
                            component: Forms
                        },
                        {
                            path: 'switches',
                            name: 'Switches',
                            component: Switches
                        },
                        {
                            path: 'tables',
                            name: 'Tables',
                            component: Tables
                        },
                        {
                            path: 'tabs',
                            name: 'Tabs',
                            component: Tabs
                        },
                        {
                            path: 'breadcrumbs',
                            name: 'Breadcrumbs',
                            component: Breadcrumbs
                        },
                        {
                            path: 'carousels',
                            name: 'Carousels',
                            component: Carousels
                        },
                        {
                            path: 'collapses',
                            name: 'Collapses',
                            component: Collapses
                        },
                        {
                            path: 'jumbotrons',
                            name: 'Jumbotrons',
                            component: Jumbotrons
                        },
                        {
                            path: 'list-groups',
                            name: 'List Groups',
                            component: ListGroups
                        },
                        {
                            path: 'navs',
                            name: 'Navs',
                            component: Navs
                        },
                        {
                            path: 'navbars',
                            name: 'Navbars',
                            component: Navbars
                        },
                        {
                            path: 'paginations',
                            name: 'Paginations',
                            component: Paginations
                        },
                        {
                            path: 'popovers',
                            name: 'Popovers',
                            component: Popovers
                        },
                        {
                            path: 'progress-bars',
                            name: 'Progress Bars',
                            component: ProgressBars
                        },
                        {
                            path: 'tooltips',
                            name: 'Tooltips',
                            component: Tooltips
                        }
                    ]
                },
                {
                    path: 'buttons',
                    redirect: '/buttons/standard-buttons',
                    name: 'Buttons',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'standard-buttons',
                            name: 'Standard Buttons',
                            component: StandardButtons
                        },
                        {
                            path: 'button-groups',
                            name: 'Button Groups',
                            component: ButtonGroups
                        },
                        {
                            path: 'dropdowns',
                            name: 'Dropdowns',
                            component: Dropdowns
                        },
                        {
                            path: 'brand-buttons',
                            name: 'Brand Buttons',
                            component: BrandButtons
                        }
                    ]
                },
                {
                    path: 'icons',
                    redirect: '/icons/coreui-icons',
                    name: 'CoreUI Icons',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'coreui-icons',
                            name: 'Icons library',
                            component: CoreUIIcons
                        },
                        {
                            path: 'brands',
                            name: 'Brands',
                            component: Brands
                        },
                        {
                            path: 'flags',
                            name: 'Flags',
                            component: Flags
                        }
                    ]
                },
                {
                    path: 'notifications',
                    redirect: '/notifications/alerts',
                    name: 'Notifications',
                    component: {
                        render(c) { return c('router-view') }
                    },
                    children: [{
                            path: 'alerts',
                            name: 'Alerts',
                            component: Alerts
                        },
                        {
                            path: 'badges',
                            name: 'Badges',
                            component: Badges
                        },
                        {
                            path: 'modals',
                            name: 'Modals',
                            component: Modals
                        }
                    ]
                }
            ]
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: 'loginStudent',
                    name: 'LoginStudent',
                    component: LoginStudent
                },
                {
                    path: 'loginTeacher',
                    name: 'LoginTeacher',
                    component: LoginTeacher
                },
                {
                    path: 'faceCheckIn',
                    name: 'FaceCheckIn',
                    component: FaceCheckIn
                },
                {
                    path: 'wfhCheckIn',
                    name: 'WFHCheckIn',
                    component: WFHCheckIn
                },
                {
                    path: 'searchBeh',
                    name: 'SearchBehScore',
                    component: SearchBehScore
                },
                {
                    path: 'reportByStd/:stdId/:schoolId',
                    name: 'ReportByStd',
                    component: ReportByStd
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register
                },
                {
                    path: 'bookMeeting',
                    name: 'BookMeeting',
                    component: BookMeeting
                },
                {
                    path: 'discussionView',
                    name: 'DiscussionView',
                    component: DiscussionView
                },
                {
                    path: 'reportUpdatedProfile/:schoolCode',
                    name: 'รายการอัพเดทระเบียนประวัติ',
                    component: ReportUpdatedRecord
                }, {
                    path: 'auth/:username/:pw',
                    name: 'ตรวจสอบสิทธิ์การเข้าถึง',
                    component: Auth
                }
            ]
        }
    ]
}