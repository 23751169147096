<template>
  <CCol>
    <CCard v-if="rows">
      <CCardHeader class="bg-light"
        ><strong>สรุปรายบันทึกประจำวิชา/ กิจกรรม/ สถานที่ </strong>
        {{ rows[0].subject_name }}</CCardHeader
      >
      <CCardBody>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'att_id', type: 'desc' },
          }"
          :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
        >
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <div v-if="props.row.teacher_id == userId">
                <button
                  class="btn btn-warning"
                  type="button"
                  @click="openModal(props.row.att_id)"
                >
                  edit
                </button>
                <button
                  class="btn btn-danger"
                  @click="removeAtt(props.row.att_id)"
                  style="margin-top: 0.2rem"
                >
                  delete
                </button>
              </div>
            </span>
          </template>
        </vue-good-table>
        <CRow style="margin-top: 20px">
          <CCol
            ><div style="text-align: right">
              <button @click="onExport" class="btn btn-secondary">
                Export
              </button>
              <!-- เพิ่มปุ่ม Export -->
            </div></CCol
          >
        </CRow>
      </CCardBody>
    </CCard>
    <div v-else>
      Loading....
    </div>
    <form
      id="formUpdateAbs"
      v-on:submit.prevent="updateData"
      method="POST"
      v-if="selectedAttId"
    >
      <CModal
        :show.sync="darkModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow
          ><!--body-->
          <CCol lg="6" v-if="selectedData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              #
            </label>
            <input
              type="text"
              name="txtAttId"
              class="form-control"
              readonly
              v-model="selectedData.att_id"
            />
          </CCol>
          <CCol lg="6" v-if="selectedData">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              วันที่
            </label>
            <datepicker
              name="txtDate"
              input-class="form-control"
              :required="true"
              format="yyyy-MM-dd"
              placeholder="วันที่จัดกิจกรรม"
              v-model="selectedData.abs_date"
            ></datepicker>
          </CCol>
          <CCol lg="12" v-if="citeriaInfo">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              เกณฑ์การหักคะแนน
            </label>
            <v-select
              :options="citeriaInfo"
              v-model="selectedData.tc_citeria_name"
              @input="setSelected"
            >
              <template #search="{ attributes, events }">
                <input
                  class="form-control"
                  v-bind="attributes"
                  v-on="events"
                  :required="!selected"
                  placeholder="search"
                />
              </template>
            </v-select>
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">แก้ไข</h3>
          </h6>
          <CButtonClose @click="darkModal = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="darkModal = false" color="danger">Discard</CButton>
          <CButton @click="updateData" color="info">Save</CButton>
        </template>
      </CModal>
    </form>
  </CCol>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx

export default {
  name: "Dashboard",
  components: { VueGoodTable, Datepicker, vSelect, XLSX },
  data() {
    return {
      statInfo: null,
      columns: [
        {
          label: "เลขที่",
          field: "att_id",
          type: "number",
        },
        {
          label: "วันที่",
          field: "abs_date",
        },
        {
          label: "คำนำหน้า",
          field: "std_title",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "วิชา/กิจกรรม/สถานที่",
          field: "subject_name",
        },
        {
          label: "ผู้รายงาน",
          field: "user_fname",
        },
        {
          label: "ประเภท",
          field: "tc_citeria_name",
        },
        {
          label: "คะแนน",
          field: "tc_citeria_score",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      userId: this.$cookies.get("user").user_id,
      showModal: false,
      selectedAttId: null,
      selectedData: null,
      citeriaInfo: null,
      selectedCiteria: null,
      responseContent: null,
      darkModal: false,
      userData:this.$cookies.get("user")
    };
  },
  mounted() {
    //FETCH SUBJECT STAT
    this.axios
      .get(
        this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=get&qSubject=" +
          this.$route.params.subjectId+'&schoolId='+this.userData.user_address
      )
      .then((response) => {
        this.rows = response.data.mainData;
        //console.log(this.rows);
      })
      .finally(() => {});
  },
  methods: {
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
            this.selectedAttId
        )
        .then((response) => {
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    updateData() {
      //SEND DATA
      const form = document.getElementById("formUpdateAbs");
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.selectedCiteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == false) {
            this.responseContent = response.data.error;
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/attendanceAPI.php?txtAction=delete&attId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
  },
};
</script>
