<template>
  <CCol lg="12">
    <CCard v-if="rows">
      <CCardHeader class="bg-warning">สรุปรายชื่อนักเรียนที่ขาด และ ลา</CCardHeader>
      <CCardBody>
        <vue-good-table :columns="columns" :rows="rows" :search-options="{ enabled: true }" :sort-options="{
          enabled: true,
          initialSortBy: { field: 'att_id', type: 'desc' },
        }" :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }">
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'abs_date'">
              <router-link :to="
                '/statByDate/' +
                props.row.semester_id +
                '/' +
                props.row.abs_date
              ">
                {{ props.row.abs_date }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'stud_id'">
              <router-link :to="'/statByStudent/' + props.row.stud_id">
                {{ props.row.stud_id }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'subject_name'">
              <router-link :to="'/statBySubject/' + props.row.subject_id">
                {{ props.row.subject_name }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'user_fname'">
              <router-link :to="'/statByTeacher/' + props.row.teacher_id">
                {{ props.row.user_fname }}
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'tc_citeria_name'">
              <router-link :to="'/statByReason/' + props.row.tc_citeria_no">
                {{ props.row.tc_citeria_name }}
              </router-link>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>

            <span v-if="props.column.field == 'actions'">
              <div v-if="props.row.teacher_id == userData.user_id">
                <button class="btn btn-warning" type="button" @click="openModal(props.row.att_id)">
                  edit
                </button>
                <button class="btn btn-danger" @click="removeAtt(props.row.att_id)" style="margin-top: 0.2rem">
                  delete
                </button>
              </div>
            </span>
          </template>
          <div slot="emptystate">
            This will show up when there are no rows
          </div>
        </vue-good-table>
        <CCol lg="12" v-if="userData">
          <div class="relative w-full mb-3" style="margin-top:20px">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              <strong>+ เพิ่มนักเรียนที่ขาด</strong>
            </label>
            <SltStudent />

          </div>
        </CCol>
        <CCol lg="12" v-if="userData">
          <div class="relative w-full mb-3">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              <strong>+ เพิ่มนักเรียนที่ลา</strong>
            </label>
            <SltStudent1 />
          </div>

        </CCol>
        <CCol lg="12" class="text-right">
          <CButton @click="addAbsenseInClass" color="warning">+Add</CButton>
        </CCol>
      </CCardBody>
    </CCard>
    <!-------MODAL EDIT----------------------------------------->
    <form id="formUpdateAbs" v-on:submit.prevent="updateData" method="POST" v-if="selectedAttId">
      <CModal :show.sync="darkModal" :no-close-on-backdrop="true" :centered="true" title="Modal title 2" size="lg"
        color="dark">
        <CRow>
          <!--body-->
          <CCol lg="6" v-if="selectedData">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              #
            </label>
            <input type="text" name="txtAttId" class="form-control" readonly v-model="selectedData.att_id" />
          </CCol>
          <CCol lg="6" v-if="selectedData">
            <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
              วันที่
            </label>
            <datepicker name="txtDate" input-class="form-control" :required="true" format="yyyy-MM-dd"
              placeholder="วันที่จัดกิจกรรม" v-model="selectedData.abs_date"></datepicker>
          </CCol>
          <CCol lg="12">
            <SltCriteria />
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">แก้ไข</h3>
          </h6>
          <CButtonClose @click="darkModal = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="darkModal = false" color="danger">Discard</CButton>
          <CButton @click="updateData" color="info">Save</CButton>
        </template>
      </CModal>
    </form>
  </CCol>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import SltSubject from "./SltSubject.vue";
import SltStudent from "./SltStudent.vue";
import SltStudent1 from "./SltStudent1.vue";
import SltCriteria from "./SltCriteria.vue";
import ChkPeriod from "./ChkPeriod.vue";
import arrayEqual from "array-equal";

export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    SltSubject,
    SltStudent,
    SltStudent1,
    ChkPeriod,
    SltCriteria
  },
  data() {
    return {
      columns: [
        {
          label: "เลขที่",
          field: "att_id",
          type: "number",
        },
        {
          label: "วันที่",
          field: "abs_date",
        },
        {
          label: "เลขประจำตัวนักเรียน",
          field: "stud_id",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "วิชา/กิจกรรม/สถานที่",
          field: "subject_name",
        },
        {
          label: "ประเภท",
          field: "tc_citeria_name",
        },
        {
          label: "คะแนน",
          field: "tc_citeria_score",
        },
        {
          label: "จำนวน (ชั่วโมง)",
          field: "hours",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      rowsExcel: null,
      userData: this.$cookies.get("user"),
      improveInfo: null,
      levelInfo: null,
      routeInfo: null,
      criteriaInfo: null,
      selectedCiteria: null,
      selectedAppointment: null,
      selectedStartDate: null,
      selectedEndDate: null,
      selectedData: null,
      responseContent: null,
      selectedAttId: null,
      darkModal: false,
      modalCreate: false,
      modalEdit: false,
      modalAlert: false,
      msgAlert: null,
      pictUrl1: "-",
      pictUrl2: "-",
      pictUrl3: "-",
      pictUrl4: "-",
      fileLink1: "-",
      fileLink2: "-",
      fileLink3: "-",
      fileLink4: "-",
      pic1New: null,
      pic2New: null,
      pic3New: null,
      pic4New: null,
      currentSemester: null,
      uploadControl: [0, 0],
      btnControl: { saving: false, saving2: false },


    };
  },
  mounted() {

    //FIND CURRENT SEMESTER
    let formSemester = new FormData();
    formSemester.append("txtAction", "get");
    formSemester.append("qSemester", "all");
    this.axios
      .post(this.$hostUrl + "php_action/semesterAPI.php", formSemester, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        //console.log(response.data);
        this.currentSemester = response.data.mainData[0].code;
        //console.log(this.currentSemester);
      });


    //FETCH ABSENSE IN SEC
    let formData = new FormData();
    formData.append('txtAction', 'getAttendanceInClass');
    formData.append('date', this.$store.state.selectedTeachingLog.teaching_date);
    formData.append('subjectId', this.$store.state.selectedSubject.code);
    formData.append('teacherId', this.userData.user_id);
    formData.append('secNo', this.$store.state.selectedClass);
    this.axios
      .post(
        this.$hostUrl +
        "php_action/attendanceAPI.php", formData
      )
      .then((response) => {
        this.rows = response.data.mainData;
        //console.log(this.rows);
      });
  },
  methods: {
    openModal(attId) {
      this.darkModal = true;
      this.selectedAttId = attId;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=get&txtAttId=" +
          this.selectedAttId
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedData = response.data.mainData;
          this.selectedCiteria = { code: this.selectedData.tc_citeria_no };
          this.$store.commit("setSelectedCriteria", { code: response.data.mainData.tc_citeria_no, label: response.data.mainData.tc_citeria_name });
        });
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
          "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.criteriaInfo = response.data.mainData;
        })
        .finally(() => { });
    },
    updateData() {
      const form = document.getElementById('formUpdateAbs');
      //console.log(form);
      const formData = new FormData(form);
      formData.append("txtAction", "update");
      formData.append("sltCiteria", this.$store.state.selectedCriteria.code);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == "updated") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });
    },
    setSelected(value) {
      this.selectedCiteria = value;
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        let formData = new FormData();
        formData.append("txtAction", "delete");
        formData.append("attId", value);
        formData.append("userId", this.userData.user_id);
        //console.log(formData);
        this.axios
          .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.deleteState == "deleted") {
              alert("บันทึกสำเร็จ");
              location.reload();
            } else {
              alert(response.data.error);
            }
          });
      } else {
        return false;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    addAbsenseInClass() {
      this.postMissStd();
      this.postLeaveStd();
    },
    postMissStd() {
      //REDUCE BEH SCORE
      if (this.$store.state.selectedStudent.length > 0) {

        let formMiss = new FormData();
        formMiss.append("txtAction", "create");
        formMiss.append("userId", this.userData.user_id);
        formMiss.append("selectedSemester", this.currentSemester);
        formMiss.append("selectedSubject", this.$store.state.selectedSubject.code);
        formMiss.append("txtDate", this.$store.state.selectedTeachingLog.teaching_date);
        formMiss.append("sltHours", JSON.parse(this.$store.state.selectedTeachingLog.teaching_periods).length);
        formMiss.append("secNo", this.$store.state.selectedClass);
        formMiss.append("txtReason", 245);
        formMiss.append("txtSchoolId", this.userData.user_address);
        formMiss.append(
          "selectedStudent",
          JSON.stringify(this.$store.state.selectedStudent)
        );
        this.axios
          .post(this.$hostUrl + "php_action/attendanceAPI.php", formMiss, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.createState == "created") {
              alert("บันทึกนักเรียน ขาดเรียน สำเร็จ");
              this.uploadControl[0] = 1;
              this.checkSuccess();
            } else {
              alert(response.data.error);
              this.btnControl.saving = false;
            }
          });
      } else {
        this.uploadControl[0] = 1;
      }
      this.checkSuccess();
    },
    postLeaveStd() {
      //REDUCE BEH SCORE
      if (this.$store.state.selectedStudent1.length > 0) {

        let formMiss = new FormData();
        formMiss.append("txtAction", "create");
        formMiss.append("userId", this.userData.user_id);
        formMiss.append("selectedSemester", this.currentSemester);
        formMiss.append("selectedSubject", this.$store.state.selectedSubject.code);
        formMiss.append("txtDate", this.$store.state.selectedTeachingLog.teaching_date);
        formMiss.append("sltHours", JSON.parse(this.$store.state.selectedTeachingLog.teaching_periods).length);
        formMiss.append("secNo", this.$store.state.selectedClass);
        formMiss.append("txtReason", 61);
        formMiss.append("txtSchoolId", this.userData.user_address);
        formMiss.append(
          "selectedStudent",
          JSON.stringify(this.$store.state.selectedStudent1)
        );
        this.axios
          .post(this.$hostUrl + "php_action/attendanceAPI.php", formMiss, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            if (response.data.createState == "created") {
              alert("บันทึกนักเรียน ลา สำเร็จ");
              this.uploadControl[1] = 1;
              this.checkSuccess();
            } else {
              alert(response.data.error);
              this.btnControl.saving = false;
            }
          });
      } else {
        this.uploadControl[1] = 1;
      }
      this.checkSuccess();
    },
    checkSuccess() {
      console.log(this.uploadControl);
      if (arrayEqual(this.uploadControl, [1, 1])) {
        location.reload();
      }
    },
  },
};
</script>
