import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store';
import 'remixicon/fonts/remixicon.css';


Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

//API
import axios from 'axios';
import VueAxios from 'vue-axios';
//Vue.prototype.$hostUrl = 'http://academic.skw.ac.th/stdAttendance/';
//Vue.prototype.$hostUrl = 'http://www.beamingschool.com/stdAttendance/';
//Vue.prototype.$hostUrl = 'https://beamingschool.com/';
Vue.prototype.$hostUrl = 'https://vrsim4learning.com/beamingschoolNeAPI/';
Vue.prototype.$hostFileUrl = 'https://sisaketwittayalai.online/';
Vue.prototype.$hostSQL_Url = 'https://21century.ipst.ac.th/';

//COOKIES
import VueCookies from 'vue-cookies';

Vue.use(VueAxios, axios);
//Cookies
Vue.use(VueCookies);
// set default config
Vue.$cookies.config('1d')


import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

//GOOGLE MAP
import * as VueGoogleMaps from 'vue2-google-maps';



Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyAjeqx0AHYkx6ovKzd8RpatREUzk7An3ho',
        libraries: 'places,drawing,visualization', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then set installComponents to 'false'.
    //// If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
});

import CoreuiVueCharts from '@coreui/vue-chartjs';
Vue.use(CoreuiVueCharts);



import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

//SWEET ALERT
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

new Vue({
    el: '#app',
    router,
    store,
    icons,
    template: '<App/>',

    components: {
        App
    }
})

export const hostUrl = Vue.prototype.$hostUrl;