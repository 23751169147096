var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-app flex-row align-items-center"},[_c('CContainer',[_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',[(_vm.rows)?_c('CCard',[_c('CCardHeader',{staticClass:"bg-dark text-white"},[_c('strong',[_vm._v("สรุปบันทึกคะแนนความประพฤติของ")]),_vm._v(" "+_vm._s(_vm.rows[0].std_id)+" "+_vm._s(_vm.rows[0].std_name)+" "+_vm._s(_vm.rows[0].std_surname)),_c('br'),_c('h3',{staticClass:"text-warning"},[_c('strong',[_vm._v("คะแนนที่เหลือ: "+_vm._s(_vm.grandTotal)+" คะแนน")])])]),_c('CCardBody',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{ enabled: true },"sort-options":{
                enabled: true,
                initialSortBy: { field: 'att_id', type: 'desc' },
              },"pagination-options":{
                enabled: true,
                mode: 'page',
                perPage: 10,
              }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return undefined}}],null,false,3854418610)},[_vm._v(" > ")])],1)],1):_vm._e()],1)],1),_c('CRow',{staticClass:"justify-content-center"},[_c('CCol',{staticClass:"text-right",attrs:{"md":"12"}},[_vm._v(" พัฒนาโดย ดร.เจตนิพิฐ แท่นทอง ")])],1)],1),_c('CModal',{attrs:{"show":_vm.darkModal,"no-close-on-backdrop":true,"centered":true,"title":"Modal title 2","size":"lg","color":"dark"},on:{"update:show":function($event){_vm.darkModal=$event}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h6',{staticClass:"modal-title"},[_vm._v("คำเตือน!!")]),_c('CButtonClose',{staticClass:"text-white",on:{"click":function($event){_vm.darkModal = false}}})]},proxy:true},{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.darkModal = false}}},[_vm._v("Discard")])]},proxy:true}])},[_vm._v(" password หรือ username ไม่ถูกต้อง ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }