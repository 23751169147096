<template>
  <CCol>
    <CCard v-if="rows" style="margin-top:20px">
      <CCardHeader class="bg-info text-white"
        ><strong>สรุปสรุปการมาปฏิบัติหน้าที่</strong>
      </CCardHeader>
      <CCardBody>
        <form id="formGetAppByDate" v-on:submit.prevent="getAppByDate" method="POST">
          <CRow style="margin-bottom: 20px">
            <CCol lg="4">
            </CCol>
            <CCol lg="4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ตั้งแต่วันที่:
                </label>
                <datepicker
                  name="startDate"
                  input-class="form-control bg-white"
                  :required="true"
                  format="yyyy-MM-dd"
                  placeholder="เลือกวันเริ่มต้นที่ต้องการ"
                  v-model="selectedStartDate"
                ></datepicker>
              </div>
            </CCol>
            <CCol lg="4">
              <div class="relative w-full mb-3">
                <label
                  class="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  จนถึงวันที่:
                </label>
                <datepicker
                  name="endDate"
                  input-class="form-control bg-white"
                  :required="true"
                  format="yyyy-MM-dd"
                  placeholder="เลือกวันสุดท้ายที่ต้องการ"
                  v-model="selectedEndDate"
                ></datepicker>
              </div>
              <div class="text-right">
                <button
                  type="submit"
                  class="btn btn-dark"
                >
                  ค้นหา
                </button>
              </div>
            </CCol>
          </CRow>
        </form>
        <vue-good-table
          :columns="columns"
          :rows="rows"
          :search-options="{ enabled: true }"
          :sort-options="{
            enabled: true,
            initialSortBy: { field: 'app_id', type: 'desc' },
          }"
          :pagination-options="{ enabled: true, mode: 'page', perPage: 10 }"
        >
          >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <div v-if="props.row.app_user_id == userData.user_id">
                <button
                  class="btn btn-warning"
                  type="button"
                  @click="openModal(props.row.app_id)"
                >
                  edit
                </button>
                <button
                  class="btn btn-danger"
                  @click="removeApp(props.row.app_id)"
                  style="margin-top: 0.2rem"
                >
                  delete
                </button>
              </div>
            </span>
            <span v-else-if="props.column.field == 'improve_name'">
              <span
                class="badge badge-secondary"
                v-if="props.row.improve_id == 1"
                >{{ props.row.improve_name }}</span
              >
              <span
                class="badge badge-warning"
                v-else-if="props.row.improve_id == 2"
                >{{ props.row.improve_name }}</span
              >
              <span
                class="badge badge-info"
                v-else-if="props.row.improve_id == 3"
                >{{ props.row.improve_name }}</span
              >
              <span
                class="badge badge-success"
                v-else-if="props.row.improve_id == 4"
                >{{ props.row.improve_name }}</span
              >
            </span>
          </template>
        </vue-good-table>
        <CRow style="margin-top: 20px">
          <CCol
            ><div style="text-align: right">
              <button @click="onExport" class="btn btn-secondary">
                Export
              </button>
              <!-- เพิ่มปุ่ม Export -->
            </div></CCol
          >
        </CRow>
      </CCardBody>
    </CCard>
    <div v-else>
      <div class="spinner-border text-secondary" role="status">
        <span class="sr-only"></span>
      </div>
      <div>Loading...</div>
    </div>
    <!-------------------MODAL AREA--------------------------------------------------------------------------------------->
    <form id="formUpdateApp" v-on:submit.prevent="updateData" method="POST">
      <CModal
        :show.sync="darkModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
        v-if="selectedAppointment"
      >
        <CRow
          ><!--body-->
          <CCol lg="6">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              #
            </label>
            <input
              type="text"
              name="appId"
              class="form-control"
              readonly
              v-model="selectedAppointment.app_id"
            />
          </CCol>
          <CCol lg="6">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              รหัสผู้บันทึก:
            </label>
            <input
              type="text"
              name="userId"
              class="form-control"
              readonly
              v-model="selectedAppointment.app_user_id"
            />
          </CCol>
          <CCol lg="6">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              วันที่:
            </label>
            <input
              type="text"
              name="txtUserId"
              class="form-control"
              readonly
              v-model="selectedAppointment.app_date"
            />
          </CCol>
          <CCol lg="12">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              คำอธิบายงาน/ ภาระกิจ:
            </label>
            <textarea
              name="txtDescription"
              class="form-control"
              v-model="selectedAppointment.app_description"
            />
          </CCol>
          <CCol lg="12">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              บันทึกการปรับปรุงพฤติกรรม:
            </label>
            <textarea
              name="txtNote"
              class="form-control"
              v-model="selectedAppointment.app_improve_note"
            />
          </CCol>
          <CCol lg="6">
            <label
              class="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              ผลการปรับปรุงพฤติกรรม:
            </label>
            <select
              class="form-control"
              name="improveId"
              v-model="selectedAppointment.improve_id"
            >
              <option value="">==เลือก==</option>
              <option
                v-for="improve in improveInfo"
                :key="improve.id"
                :value="improve.improve_id"
              >
                {{ improve.improve_name }}
              </option>
            </select>
          </CCol>
        </CRow>
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">แก้ไข</h3>
          </h6>
          <CButtonClose @click="darkModal = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="darkModal = false" color="danger">Discard</CButton>
          <button type="submit" class="btn btn-info">Save</button>
        </template>
      </CModal>
    </form>
    <!-------------------MODAL ALERT------------------------>
    <CModal
        :show.sync="modalAlert"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="danger"
      >
        {{msgAlert}}
        <template #header>
          <h6 class="modal-title">
            <h3 class="text-3xl font-semibold">คำเตือน</h3>
          </h6>
          <CButtonClose @click="modalAlert = false" class="text-white" />
        </template>
        <template #footer>
          <CButton @click="modalAlert = false" color="danger">Discard</CButton>
        </template>
      </CModal>
  </CCol>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx

export default {
  name: "Dashboard",
  components: { VueGoodTable, Datepicker, vSelect, XLSX },
  data() {
    return {
      statInfo: null,
      columns: [
        {
          label: "#",
          field: "app_id",
          type: "number",
        },
        {
          label: "วันที่",
          field: "app_date",
        },
        {
          label: "เลขประจำตัว",
          field: "app_std_id",
        },
        {
          label: "คำนำหน้า",
          field: "std_title",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "ชั้น",
          field: "level_fullName",
        },
        {
          label: "ห้อง",
          field: "std_class",
        },
        {
          label: "ภาระ/ คำอธิบาย นัดหมาย",
          field: "app_description",
        },
        {
          label: "ผลการพิจารณา",
          field: "improve_name",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      rowsExcel:null,
      userData: this.$cookies.get("user"),
      showModal: false,
      selectedData: null,
      citeriaInfo: null,
      improveInfo: null,
      selectedCiteria: null,
      selectedAppointment: null,
      selectedStartDate:null,
      selectedEndDate:null,
      responseContent: null,
      darkModal: false,
      modalAlert:false,
      msgAlert:null,
    };
  },
  mounted() {
    //console.log(this.userData);
    //CHECK IS LOGED IN
    if (this.userData == null) {
      alert("please login");
      this.$router.push({ path: "/pages/login" });
    }

    var isoDate = new Date().toISOString().substring(0,10);

    //FETCH ATTENDANCE
    this.axios
      .get(
        this.$hostUrl +
          "php_action/checkinAPI.php?txtAction=getAttendanceByDate&startDate=" +
          this.userData.user_address
      )
      .then((response) => {
        this.rows = response.data.mainData;
        this.rowsExcel = response.data.excelData;
        console.log(response.data);
      })
      .finally(() => {});
  },
  methods: {
    openModal(appId) {
      this.darkModal = true;
      //FETCH SELECTED RECORD
      this.axios
        .get(
          this.$hostUrl +
            "php_action/appointmentAPI.php?txtAction=getSingleAppointment&appId=" +
            appId
        )
        .then((response) => {
          //console.log(response.data);
          this.selectedAppointment = response.data.mainData;
        })
        .finally(() => {});
      //FETCH CITERIA DATA
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=get&qCiteria=all"
        )
        .then((response) => {
          this.citeriaInfo = response.data.mainData;
        })
        .finally(() => {});
    },
    updateData(event) {
      //SEND DATA
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "updateApppointment");
      this.axios
        .post(this.$hostUrl + "php_action/appointmentAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.updateState == true) {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
          }
        })
        .finally(() => {});
    },
    removeApp(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/appointmentAPI.php?txtAction=delete&appId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.deleteState == true) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rowsExcel);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    getAppByDate(event) {
      if(this.selectedStartDate == null || this.selectedEndDate == null){
        this.msgAlert = "ต้องเลือกทั้งวันเริ่มต้น และวันสิ้นสุดของการค้นหา";
        this.modalAlert = true;
        return false;
      }
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "getAppointmentByDate");
      formData.append("schoolId", this.userData.school_code);
      this.axios
        .post(this.$hostUrl + "php_action/appointmentAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          this.rows = response.data.mainData;
          this.rowsExcel = response.data.excelData;
        })
        .finally(() => {});
    },
  },
};
</script>
