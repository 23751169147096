<template>
  <CCol>

    <TblMyTeachingLog />
  </CCol>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import XLSX from "xlsx"; // import xlsx;
import TblMyTeachingLog from "../views/components/TblMyTeachingLog";


export default {
  name: "Dashboard",
  components: {
    VueGoodTable,
    Datepicker,
    vSelect,
    XLSX,
    TblMyTeachingLog,

  },
  data() {
    return {
      columns: [
        {
          label: "เลขที่",
          field: "att_id",
          type: "number",
        },
        {
          label: "วันที่",
          field: "abs_date",
        },
        {
          label: "เลขประจำตัวนักเรียน",
          field: "stud_id",
        },
        {
          label: "ชื่อ",
          field: "std_name",
        },
        {
          label: "นามสกุล",
          field: "std_surname",
        },
        {
          label: "วิชา/กิจกรรม/สถานที่",
          field: "subject_name",
        },
        {
          label: "ผู้รายงาน",
          field: "user_fname",
        },
        {
          label: "ประเภท",
          field: "tc_citeria_name",
        },
        {
          label: "คะแนน",
          field: "tc_citeria_score",
        },
        {
          label: "Actions",
          field: "actions",
          width: "10%",
        },
      ],
      rows: null,
      userId: this.$cookies.get("user").user_id,
      citeriaInfo: null,
      selectedCiteria: null,
      responseContent: null,
      darkModal: false,
      exportingData: null,
      teacherInfo: null,
      selectedTeacher: null,
      userData: this.$cookies.get("user"),
      activeBtn:'btnAll'
    };
  },
  mounted() {
    //FETCH TEACHER DROPDOWN
    this.axios
      .get(
        this.$hostUrl +
          "php_action/teachingAPI.php?txtAction=getTeachingLogBySchool&schoolId=" +
          this.userData.user_address
      )
      .then((response) => {
        this.teacherInfo = response.data.mainData;
      })
      .finally(() => {});
  },
  methods: {
    filterByUserId(userId, btn, schoolId) {
      this.activeBtn = btn;
      //FETCH TEACHING LOG BY SCHOOL
      this.axios
        .get(
          this.$hostUrl +
            "php_action/teachingAPI.php?txtAction=getTeachingLogByTeacher&userId=" +
            userId+"&schoolId="+schoolId
        )
        .then((response) => {
          console.log(response.data);
          this.$store.commit("setTeachingLogList", response.data.mainData);
          this.rowsExcel = response.data.excelData;
          //console.log(this.$store.state.teachingLogList);
        })
        .finally(() => {});
    },
    removeAtt(value) {
      var r = confirm("แน่ใจว่าต้องการลบข้อมูล?");
      if (r == true) {
        //SEND DATA
        this.axios
          .get(
            this.$hostUrl +
              "php_action/attendanceAPI.php?txtAction=delete&attId=" +
              value
          )
          .then((response) => {
            console.log(response.data);
            if (response.data.error == false) {
              this.responseContent = response.data.error;
              location.reload();
            } else {
              this.responseContent = response.data.error;
              alert(this.responseContent);
            }
          })
          .finally(() => {});
      } else {
        return true;
      }
    },
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet(this.rows);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS);
      XLSX.writeFile(wb, "export.xlsx");
    },
    updateTableForTeacher() {
      let teacherId = this.selectedTeacher.code;

      //FETCH Semester Stats BY TEACHER
      this.axios
        .get(
          this.$hostUrl +
            "php_action/attendanceAPI.php?txtAction=getStatByTeacher&selectedSemester=" +
            this.$route.params.semesterId +
            "&teacherId=" +
            teacherId
        )
        .then((response) => {
          this.rows = response.data.mainData;
          if (this.rows == undefined) {
            this.rows = [{}];
          }
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
.ui-input-text {
  border-color: transparent;
}


</style>
