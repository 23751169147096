<template>
  <div class="relative w-full mb-3" v-if="$store.state.subjectDropdown">
    <label
      class="block uppercase text-gray-700 text-xs font-bold mb-2"
      htmlFor="grid-password"
    >
      รายวิชา/ สถานที่พบเจอ
    </label>
    <v-select
      :options="$store.state.subjectDropdown"
      v-model="$store.state.selectedSubject"
      @input="mutateSelectedSubject"
    >
      <template #search="{ attributes, events }">
        <input
          class="from-control ui-input-text"
          v-bind="attributes"
          v-on="events"
        />
      </template>
    </v-select>
    <div class="text-right">
      <button
        type="button"
        @click="openAddingSubject"
        color="link"
        class="btn btn-link"
      >
        <i class="ri-add-box-line"></i>
      </button>
    </div>
    <!---------MODAL ADD SUBJECT------------>
    <form id="formCreateSubject" v-on:submit.prevent="addSubject" method="POST">
      <CModal
        :show.sync="darkModal"
        :no-close-on-backdrop="true"
        :centered="true"
        title="Modal title 2"
        size="lg"
        color="dark"
      >
        <CRow>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                รหัสโรงเรียน
              </label>
              <input
                type="text"
                name="schoolCode"
                class="form-control"
                readonly
                :value="userData.user_address"
              /></div
          ></CCol>
        </CRow>
        <CRow>
          <CCol lg="6">
            <div class="relative w-full mb-3" v-if="userData">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                รหัสวิชา/ รหัสสถานที่
              </label>
              <input
                type="text"
                name="subjectCode"
                class="form-control"
                aria-label="Username"
                aria-describedby="basic-addon1"
                required
              />
            </div>
          </CCol>
          <CCol lg="6" v-if="userData"
            ><div class="relative w-full mb-3">
              <label
                class="block uppercase text-gray-700 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                ชื่อวิชา/ สถานที่พบเจอ
              </label>
              <input
                type="text"
                name="subjectName"
                class="form-control"
                required
              /></div
          ></CCol>
        </CRow>

        <template #header>
          <h6 class="modal-title">เพิ่มรายวิชา</h6>
          <CButtonClose @click="darkModal = false" class="text-white" />
        </template>
        <template #footer>
          <button type="submit" class="btn btn-yellow">Save</button>
          <button class="btn btn-black" @click="darkModal = false">
            Discard
          </button>
        </template>
      </CModal>
    </form>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      subjectInfo: null,
      userData: this.$cookies.get("user"),
      selectedSubject: null,
      selectedStudent: null,
      selectedCiteria: null,
      responseContent: null,
      showModal: false,
      modalSuccess: null,
      newCiteria: null,
      darkModal: false,
    };
  },
  mounted() {
    

    //FETCH SUBJECT DATA
    this.axios
      .get(
        this.$hostUrl + "php_action/subjectAPI.php?txtAction=getSubject&schoolCode="+this.userData.user_address
      )
      .then((response) => {
        this.subjectInfo = response.data.mainData;
        this.$store.commit("setSubjectDropdown", response.data.mainData);
        //console.log(response.data);
      })
      .finally(() => {});
  },
  methods: {
    mutateSelectedSubject(value) {
      this.$store.commit("setSelectedSubject", value);
    },
    sendData() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateAbsense");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      formData.append("selectedSemester", this.selectedSemester.code);
      formData.append("selectedSubject", this.selectedSubject.code);
      formData.append("txtReason", this.selectedCiteria.code);
      let jsonArr = JSON.stringify(this.selectedStudent);
      formData.append("selectedStudent", jsonArr);
      this.axios
        .post(this.$hostUrl + "php_action/attendanceAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.error == "") {
            this.showModal = true;
            this.modalSuccess = true;
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            this.showModal = true;
            this.modalSuccess = false;
            alert(this.responseContent);
            location.reload();
          }
        });
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
      location.reload();
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject(event) {
      const form = document.getElementById(event.target.id);
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.createState == "created") {
            alert("บันทึกสำเร็จ");
            location.reload();
          } else {
            alert(response.data.error);
          }
        });
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 10%;
}
</style>
