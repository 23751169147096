<template>
  <div class="relative w-full mb-3">
    <label class="block uppercase text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
      ห้อง/ กลุ่ม:
    </label>
    <select name="stdClass" class="form-control" required @select="mutateSelectedClass"
      v-model="$store.state.selectedClass" @change="updateLists()">
      <option value="">-----เลือก-----</option>
      <option v-for="className in classInfo" :key="className.id" :value="className.std_class">
        {{ className.std_class }}
      </option>
    </select>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    vSelect,
  },
  data() {
    return {
      userData: this.$cookies.get("user"),
      criteriaInfo: null,
      criteriaRow: null,
      classInfo: null,
    };
  },
  mounted() {
    //FETCH CLASS DROPDOWN
    this.axios
      .get(
        this.$hostUrl +
        "php_action/classAPI.php?txtAction=getAll&schoolId=" +
        this.userData.user_address
      )
      .then((response) => {
        this.classInfo = response.data.mainData;
      });
  },
  methods: {
    mutateSelectedClass(value) {
      this.$store.commit("setSelectedClass", value);

    },
    updateLists() {
      if(this.$store.state.selectedSubjectMode == 1){
        this.updateStudentList();
      }
      else if(this.$store.state.selectedSubjectMode == 2){
        this.updateStudentListSec();
      }


    },
    updateStudentList() {
      //FETCH STUDENT LIST
      let formData = new FormData();
      formData.append("txtAction", "getStdByLevelClassSchool");
      formData.append("levelCode", this.$store.state.selectedLevel.code);
      formData.append("class", this.$store.state.selectedClass);
      formData.append("schoolCode", this.userData.user_address);
      this.axios
        .post(this.$hostUrl + "php_action/userAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          response.data.mainData.forEach((element) => {
            element.attType = 1;
          });
          this.$store.commit("setStudentList", response.data.mainData);
          this.$store.commit("setTotalStudent", response.data.mainData.length);
          //console.log(this.$store.state.studentList);
        });
    },
    updateStudentListSec() {
      //console.log('hello');
      //FETCH STUDENT LIST
      let formData = new FormData();
      formData.append("txtAction", "getEnrollemtBySecSubjectSemester");
      formData.append("secNo", this.$store.state.selectedClass);
      formData.append("subjectId", this.$store.state.selectedSubject.code);
      formData.append("semesterId", this.$store.state.selectedSemester.code);
      this.axios
        .post(this.$hostUrl + "php_action/enrollmentAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          response.data.mainData.forEach((element) => {
            element.attType = 1;
          });
          this.$store.commit("setStudentListSec", response.data.mainData);
          this.$store.commit("setTotalStudent", response.data.mainData.length);
          //console.log(this.$store.state.studentListSec);
        });
    },
    openAddingSubject() {
      this.darkModal = true;
    },
    addSubject() {
      console.log(this.selectedStudent);
      const form = document.getElementById("formCreateSubject");
      const formData = new FormData(form);
      formData.append("txtAction", "create");
      this.axios
        .post(this.$hostUrl + "php_action/subjectAPI.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response.data);
          if (response.data.error == null) {
            this.responseContent = "บันทึกสำเร็จ";
            alert(this.responseContent);
            location.reload();
          } else {
            this.responseContent = response.data.error;
            alert(this.responseContent);
            location.reload();
          }
        })
        .finally(() => { });
    },
  },
};
</script>
<style scoped>
.ui-input-text {
  border-width: 0px;
  border: none;
  width: 20%;
}
</style>
